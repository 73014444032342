import { createTheme } from '@mui/material/styles';

// Create your theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#fff',
    },
  },
});

// Global styles
const globalStyles = {
  // Add global styles here if needed
};

export { theme, globalStyles }; // Correctly exporting named exports

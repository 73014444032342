import React from 'react';
import { ThemeProvider, CssBaseline, Container } from '@mui/material';
import { theme } from './theme'; // theme.js dosyasından tema import ediyoruz
import Home from './pages/Home';
import backgroundImg from './assets/background.jpg'; // Arka plan resmi import edildi

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Normalize.css için */}
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`, // Arka plan resmi eklendi
          backgroundSize: 'cover', // Resmin tamamen kaplamasını sağlar
          backgroundPosition: 'center', // Resmin ortalanmasını sağlar
          backgroundRepeat: 'no-repeat', // Resmin tekrarlanmasını engeller
          minHeight: '100vh',
          position: 'relative', // İçerik üzerinde düzgün bir şekilde konumlandırılması için
        }}
      >
        <Container
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // İçeriğin daha fazla şeffaf arka planı
            borderRadius: '8px', // İçerik kutusu köşe yuvarlama
            padding: '16px', // İçerik kutusuna iç boşluk ekleme
            maxWidth: '100%',
            height: '100%', // İçeriğin tam ekran olmasını sağlamak için
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // İçerik üzerinde düzgün bir şekilde konumlandırılması için
            overflow: 'hidden', // İçerik kutusunun taşmasını engellemek için
          }}
        >
          <Home />
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default App;
